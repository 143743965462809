<template>
  <div class="m-modal-authentication">
    <SfOModal
      :visible="isVisible"
      :is-bar-close="true"
      transition-overlay=""
      transition-modal=""
      :title="$t('Login or register')"
      :overlay="!isMobile"
      class="modal-authentication"
      :class="{'bottom-navigation--show': modalData.payload === 'auth'}"
      @close="closeModal"
    >
      <MAuth
        v-if="isVisible"
        :current-user="currentUser"
        :is-new-user="isNewUser"
        @close="closeModal"
      />
    </SfOModal>
  </div>
</template>

<script>
import SfOModal from 'theme/components/storefront-override/SfOModal';
import DeviceType from 'theme/mixins/DeviceType';
import { mapMutations, mapState } from 'vuex';
import MAuth from 'theme/components/molecules/m-auth.vue';

export default {
  name: 'MModalAuthentication',
  components: { MAuth, SfOModal },
  mixins: [DeviceType],
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.current,
      isNewUser: state => state.userInfo.isNewUser
    })
  },
  watch: {
    currentUser: {
      deep: true,
      handler (val) {
        if (!val) return

        if (this.isNewUser) return

        this.closeModal()
      }
    }
  },
  methods: {
    ...mapMutations({
      closeMenu: 'ui/closeMenu'
    }),
    closeModal () {
      this.step = 'sign'
      this.$emit('close', this.modalData.name)

      if (this.modalData?.closeMenu) {
        this.closeMenu()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/third-party-components/vue-verification-code-input";

.modal-authentication {
  @media (min-width: $tablet-min) {
    --modal-width: 530px;
    --modal-top: 50%;
    --modal-left: 50%;
    --modal-bottom: none;
    --modal-right: none;
    --modal-transform: translate3d(-50%, -50%, 0);
    --modal-height: auto;
    --modal-content-padding: var(--spacer-50);
  }

  ::v-deep {
    .mobile-only {
      @media (min-width: $mobile-max) {
        display: none !important;
      }
    }

    .sf-bar {
      border-bottom: 2px solid var(--light-gray);

      @media (max-width: $mobile-max) {
        div:nth-child(2) {
          display: flex;
          flex: 1;
          justify-content: center;
        }
      }

      .sf-bar__title {
        font-size: var(--font-size-20);
        font-weight: var(--font-medium);
        line-height: var(--font-size-24);
        padding: var(--spacer-16) 0;
      }
    }

    .sf-bar-title--start {
      @media (max-width: $mobile-max) {
        div:nth-child(2) {
          justify-content: flex-start;
          padding: var(--spacer-16) 0 var(--spacer-16) var(--spacer-15);
        }
      }
    }

    .sf-input--has-text, .sf-input input[type="date"] ~ *, .sf-input input[type="time"] ~ *, .sf-input input[type="image"] ~ *, .sf-input input[type="file"] ~ * {
      --input-label-top: 20%;
    }

    .bottom-navigation--show {
      .sf-modal__container,
      .sf-modal__overlay {
        @media (max-width: $mobile-max) {
          bottom: px2rem(69);
          z-index: 3;
          border-bottom: 1px solid var(--gray-transparent);
        }
      }
    }

    .sf-modal__close {
      right: var(--spacer-20);
      top: var(--spacer-10);
    }

    .sf-modal__content {
      padding: var(--spacer-sm) var(--spacer-50) var(--spacer-20);
      font-family: var(--font-family-inter);

      @media (max-width: $mobile-max) {
        height: 100%;
        padding: var(--spacer-20) var(--spacer-10) var(--spacer-20);
      }
    }

    .sf-input {
      width: 100%;
      --input-label-font-size: var(--font-size-12);

      input {
        border: 1px solid transparent;
        background: var(--light-gray);
        --input-bar-display: none;

        &:focus {
          border: 1px solid var(--orange);
          box-sizing: border-box;

          & ~ * {
            --input-label-top: 20%;
            --input-label-color: var(--dark-gray);
            --input-label-font-size: var(--font-size-12);
          }
        }

        &:focus-visible {
          outline: none;
          border: 1px solid var(--orange);
          box-sizing: border-box;
        }
      }

      &__bar {
        position: relative;
        display: var(--input-bar-display, none);
      }
    }

    .sf-input__label:after {
      content: '';
    }
  }
}
</style>
